import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/monitor/:id',
    name: 'pcscreen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MonitorView.vue'),
    meta: {
      hideNavbar: true
    }
  },
  {
    path: '/monitor_time/:id/:tn/:cn',
    name: 'pcscreentime',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MonitorViewTime.vue'),
    meta: {
      hideNavbar: true
    }
  },
  {
    path: '/monitor_export/:id/:tn/:cn',
    name: 'pcscreenexport',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MonitorViewExport.vue'),
    meta: {
      hideNavbar: true
    }
  },
  {
    path: '/p3001',
    name: 'sdfsdfee',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PublishThreeZeroZeroOne.vue'),
    meta: {
      hideNavbar: true
    }
  },
  {
    path: '/p3001login',
    name: 'sdfsdfdddee',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PublishThreeZeroZeroOneLogin.vue'),
    meta: {
      hideNavbar: true
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }//,
  // {
  //   path: '*',
  //   name: 'none',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: { template: '<div>Not Found</div>' }
  // }
]

// history: createWebHistory(process.env.BASE_URL),

const router = createRouter({
  history: createWebHistory('/'),
  routes
})

export default router
