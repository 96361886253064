<template>

  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav>

  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="생활의 편리함을 주는 CP센서 입니다."/>
  </div>
</template>

<script>
// @ is an alias to /src

import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  async mounted () {
    const l = this.$route.query.l
    const i = this.$route.query.i
    // console.log(q)
    // alert(l)
    if (l === 'm') {
      // alert(l)
      // alert(i)
      const ur = ''
      const ur2 = ur.concat('/monitor/', i) // , '/', this.model[this.prop], '/', '1')
      this.$router.push(ur2)
    }
  },
  components: {
    HelloWorld
  }
}
</script>
