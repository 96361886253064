<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p>
      온도계를 구매하시려면 스마트스토어를 방문해 주세요<br>
      스마트스토어 주소:
      <a href="https://smartstore.naver.com/cpsensor" target="_blank" rel="noopener">smartstore.naver.com/cpsensor</a>.
    </p>
    <h3>제품 지원</h3>
    <p>
      제품에 대한 새로운 소식이나 정보를 보시려면 공식까페를 방문해 주세요. <br>
      네이버 까페 주소:
      <a href="https://cafe.naver.com/cpsensor" target="_blank" rel="noopener">cafe.naver.com/cpsensor</a>.
    </p>
    <!-- <h3>관리자 모드</h3>
     <p>
     퍼블리싱한 제품들을 관리 할 수 있습니다.
    </p>
     <router-link to="/p3001">축산기업중앙회</router-link>
     -->

    <!-- <ul>
      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-babel" target="_blank" rel="noopener">babel</a></li>
      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-router" target="_blank" rel="noopener">router</a></li>
      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-vuex" target="_blank" rel="noopener">vuex</a></li>
      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-eslint" target="_blank" rel="noopener">eslint</a></li>
    </ul> -->
    <!-- <h3>Essential Links</h3>
    <ul>
      <li><a href="https://vuejs.org" target="_blank" rel="noopener">Core Docs</a></li>
      <li><a href="https://forum.vuejs.org" target="_blank" rel="noopener">Forum</a></li>
      <li><a href="https://chat.vuejs.org" target="_blank" rel="noopener">Community Chat</a></li>
      <li><a href="https://twitter.com/vuejs" target="_blank" rel="noopener">Twitter</a></li>
      <li><a href="https://news.vuejs.org" target="_blank" rel="noopener">News</a></li>
    </ul>
    <h3>Ecosystem</h3>
    <ul>
      <li><a href="https://router.vuejs.org" target="_blank" rel="noopener">vue-router</a></li>
      <li><a href="https://vuex.vuejs.org" target="_blank" rel="noopener">vuex</a></li>
      <li><a href="https://github.com/vuejs/vue-devtools#vue-devtools" target="_blank" rel="noopener">vue-devtools</a></li>
      <li><a href="https://vue-loader.vuejs.org" target="_blank" rel="noopener">vue-loader</a></li>
      <li><a href="https://github.com/vuejs/awesome-vue" target="_blank" rel="noopener">awesome-vue</a></li>
    </ul> -->
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>
 <!-- <h3>Installed CLI Plugins</h3> -->
 <!--    <ul> -->
  <!--     <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-babel" target="_blank" rel="noopener">babel</a></li> -->
 <!--      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-router" target="_blank" rel="noopener">router</a></li> -->
 <!--      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-vuex" target="_blank" rel="noopener">vuex</a></li> -->
 <!--      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-eslint" target="_blank" rel="noopener">eslint</a></li> -->
  <!--   </ul> -->
 <!--    <h3>Essential Links</h3> -->
 <!--    <ul> -->
 <!--      <li><a href="https://vuejs.org" target="_blank" rel="noopener">Core Docs</a></li> -->
  <!--     <li><a href="https://forum.vuejs.org" target="_blank" rel="noopener">Forum</a></li> -->
  <!--     <li><a href="https://chat.vuejs.org" target="_blank" rel="noopener">Community Chat</a></li> -->
  <!--     <li><a href="https://twitter.com/vuejs" target="_blank" rel="noopener">Twitter</a></li> -->
  <!--     <li><a href="https://news.vuejs.org" target="_blank" rel="noopener">News</a></li> -->
  <!--   </ul> -->
  <!--   <h3>Ecosystem</h3> -->
  <!--   <ul> -->
  <!--     <li><a href="https://router.vuejs.org" target="_blank" rel="noopener">vue-router</a></li> -->
  <!--     <li><a href="https://vuex.vuejs.org" target="_blank" rel="noopener">vuex</a></li> -->
  <!--     <li><a href="https://github.com/vuejs/vue-devtools#vue-devtools" target="_blank" rel="noopener">vue-devtools</a></li> -->
  <!--     <li><a href="https://vue-loader.vuejs.org" target="_blank" rel="noopener">vue-loader</a></li> -->
  <!--     <li><a href="https://github.com/vuejs/awesome-vue" target="_blank" rel="noopener">awesome-vue</a></li> -->
  <!--   </ul> -->
  <!--  </div> -->
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
